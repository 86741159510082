import { React, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const GameDataModal = (props) => {
  let dispatch = useDispatch();
  const contractData = useSelector((state) => state.contract);

  const [counter, setCounter] = useState(0);
  const forwardCounter = () => {
    setCounter(counter == 2 ? counter : counter + 1);
    console.log("TEST COUNTERR", counter == 2 ? counter : counter + 1);
  };

  const reverseCounter = () => {
    setCounter(counter == 0 ? counter : counter - 1);
    console.log(
      "TEST COUNTERR",
      props.safeSides,
      props.allUsers[contractData.globalStage],
      props.myNfts,
      props.countedArray
    );
  };
  return (
    <>
      <Modal
        className="mynft-list restart nfts-data"
        show={props.show}
        cancel={props.close}
        size="lg"
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title>
            {counter == 0
              ? "Safe Side"
              : counter == 1
              ? "Total Nft's"
              : "My Nft's"}
          </Modal.Title>

          {/* <button type="button" className="close" onClick={props.close}>
            <span aria-hidden="true">&times;</span>
          </button> */}
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper nft-content">
            {console.log("GAME DATA MODAL Testing000>>>>>>>",props.myNfts[contractData.globalStage],props.safeSides[contractData.globalStage - 1])}
            {counter === 0 ? (
              contractData.globalStage > 0 ? (
                props.safeSides[contractData.countDownStageNumber - 1]?.safeTile !==
                undefined ? (
                  <div className="nft-label">
                    <h2>
                      {" "}
                      safe side is :{" "}
                      {props.safeSides[contractData.countDownStageNumber - 1]?.safeTile <
                      50
                        ? "Left Tile"
                        : "Right Tile"}{" "}
                    </h2>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : counter === 1 ? (
              <div className="nft-label">
                <h2>
                  Total Safe Nfts :{" "}
                  {props.safeSides[contractData.countDownStageNumber - 1]?.safeTile < 50 && props.countedArray[contractData.countDownStageNumber ] !== undefined
                    ? props.countedArray[contractData.countDownStageNumber ].leftUsers
                    : props.countedArray[contractData.countDownStageNumber ].rightUsers}
                </h2>
                <h2>
                  Total Died Nfts :{" "}
                  {props.safeSides[contractData.countDownStageNumber  - 1]?.safeTile < 50  && props.countedArray[contractData.countDownStageNumber ] !== undefined
                    ? props.countedArray[contractData.countDownStageNumber ].rightUsers
                    : props.countedArray[contractData.countDownStageNumber ].leftUsers}
                </h2>
              </div>
            ) : counter === 2 ? (
              <div className="nft-label">
                <h2>
                  My Safe Nfts :{" "}
                  {props.safeSides[contractData.countDownStageNumber  - 1]?.safeTile !==
                    undefined && props.myNfts[contractData.countDownStageNumber ] !== undefined &&
                  props.safeSides[contractData.countDownStageNumber  - 1].safeTile < 50
                    ? props.myNfts[contractData.countDownStageNumber ]?.filter(
                        (data) => data.lastJumpSide == false
                      ).length
                    : props.myNfts[contractData.countDownStageNumber ]?.filter(
                        (data) => data.lastJumpSide == true
                      ).length}
                </h2>
                <h2>
                  My Died Nfts :{" "}
                  {props.safeSides[contractData.countDownStageNumber  - 1]?.safeTile !==
                    undefined && props.myNfts[contractData.countDownStageNumber ] !== undefined && 
                  props.safeSides[contractData.countDownStageNumber  - 1].safeTile < 50
                    ? props.myNfts[contractData.countDownStageNumber ]?.filter(
                        (data) => data.lastJumpSide == true
                      ).length
                    : props.myNfts[contractData.countDownStageNumber ]?.filter(
                        (data) => data.lastJumpSide == false
                      ).length}
                </h2>
              </div>
            ) : (
              ""
            )}

            <div className="d-flex">
              {counter !== 0 ? (
                <Button
                  className="btn-connect"
                  onClick={() => reverseCounter()}
                >
                  Back
                </Button>
              ) : (
                ""
              )}
              {counter == 2 ? (
                <Button className="btn-connect" onClick={props.close}>
                  Close
                </Button>
              ) : (
                <Button
                  className="btn-connect"
                  onClick={() => forwardCounter()}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GameDataModal;