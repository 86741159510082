import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RELOAD_POPUP_TRUE, RELOAD_POPUP_FALSE } from "../../redux/actionType";

const Timer = (props) => {
  
    let dispatch = useDispatch();
    const contractData = useSelector((state) => state.contract);
    const [remain, setRemain] = useState(0)
    const [remainFormateed, setRemainFormateed] = useState(0)


    useEffect(() => {

        let interval
        interval = setInterval(setRemaningSlotTime, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [contractData.contractInstance, contractData.globalStage]);


    useEffect(() => {
        if (remain > 0) {
            timeFormatterFunction();
        }
    }, [remain]);

    const timeFormatterFunction = () => {
        let scnds = remain % 60;
        let minutes = parseInt(remain / 60);
        let minutesActual = minutes % 60;
        let hours = parseInt(minutes / 60);
        setRemainFormateed(`${hours} Hours - ${minutesActual} Minutes - ${scnds} Seconds`);
    }


    const setRemaningSlotTime = () => {
        const startTime = contractData.startGameTime;
        if (startTime !== 0 && startTime < Math.floor(Date.now() / 1000)) {
            const slot = Number(process.env.REACT_APP_TIMESLOT);
            const currentTime = Math.floor(Date.now() / 1000);
            let remaningTime = (currentTime - startTime) % slot;
            remaningTime = slot - remaningTime
            if (remaningTime == slot - 2 ) {
                if (
                    contractData.latestTimestampOfUsers > contractData?.gameEndDays ||
                    (contractData?.isDynamicEnd === false &&
                        contractData.gameInitializeDay !== 0 &&
                        contractData?.safetiles[contractData?.globalStage - 2] !== undefined  // 2 by zubair
                    )

                ) {
                    // debugger
                    console.log("REMAING SLOTTTTT>>>>>>> False");
                    dispatch({
                        type: RELOAD_POPUP_FALSE,
                        payload: false,
                    });
                }
                else {
                    // debugger
                    console.log("REMAING SLOTTTTT>>>>>>> True");
                    dispatch({
                        type: RELOAD_POPUP_TRUE,
                        payload: true,
                    });
                }
            }

            setRemain(remaningTime);
        } else {
            setRemain(0);
        }
    }
    return (

        <div className="out-timer">
            {/* {console.log(contractData?.gameEndDays, contractData.latestTimestampOfUsers, "aaaaaaaaaaaa")} */}
            {contractData.latestTimestampOfUsers > contractData?.gameEndDays || (contractData?.isDynamicEnd === false && contractData.gameInitializeDay !== 0 &&
            contractData?.safetiles[contractData?.globalStage - 1] !== undefined) ?
                "Game Ended" :
                remain ? remainFormateed : 'Game not started yet'
            }
            {/* {contractData?.isDynamicEnd === false && contractData.gameInitializeDay !== 0 ?
                "Game Ended" :
                remain ? remainFormateed : 'Game not started yet'
            } */}

        </div>
    );
};

export default Timer;
