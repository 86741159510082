import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import timer from '../../assets/images/white-timer.png'
import { useDispatch, useSelector } from "react-redux";
// import {closeModal} from '../../redux/connectWallet/action'

const RemainingNftModal = (props) => {
  const [show, setshow] = useState(true)
  // const [rightsafe, setrightsafe] = useState(false)
  // const [leftsafe, setleftunsafe] = useState(false)

  // let dispatch = useDispatch();
  const contractData = useSelector((state) => state.contract);
  // console.log(props.allUsers[contractData.globalStage], "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
  //  debugger
  let rightsafe = false;
  let leftsafe = false;
  let leftarray = [];
  let dieArray = [];
  // const NftLft = () => {
  //   let safeNFT = props.safeSides[contractData.globalStage - 1]?.safe;
  //   // debugger
  // }
  const handleClose = () => {
    setshow(false)
  }

  return (
    <div className={`died-nfts-wrapper  ${show ? "show":''}`}>
      
          <div className="nft-header">
            <h3>NFT's Stats</h3>
            {/* <button type="button" className="close" onClick={() => handleClose()}>
              <span aria-hidden="true">&times;</span>
            </button> */}
          </div>
         
        
          <div className="nft-stats">
        {
          props.allUsers &&
            props.allUsers.map((data, i) => {
              //   if(i > 0){
              data.map((usersData) => {
                if (i == 0) {
                  leftarray.push(usersData);
                }
                if ( i !== 0 && i == contractData?.globalStage && props.safeSides[i - 1]?.safeTile == undefined ) {
                  leftarray.push(usersData);
                }

                if (
                  (usersData.lastJumpSide == true &&
                    props.safeSides[i - 1]?.safeTile !== undefined &&
                    props.safeSides[i - 1]?.safeTile >= 50) ||
                  (usersData.lastJumpSide == false &&
                    props.safeSides[i - 1]?.safeTile !== undefined &&
                    props.safeSides[i - 1]?.safeTile < 50)
                ) {
                  leftarray.push(usersData);
                }
                if (
                  (usersData.lastJumpSide == true &&
                    props.safeSides[i - 1]?.safeTile !== undefined &&
                    props.safeSides[i - 1]?.safeTile < 50) ||
                  (usersData.lastJumpSide == false &&
                    props.safeSides[i - 1]?.safeTile !== undefined &&
                    props.safeSides[i - 1]?.safeTile >= 50)
                ) {
                  dieArray.push(usersData);
                }
              });
            })

          //   if (props.safeSides[contractData.globalStage - 1]?.safeTile !== undefined
          //     && props.safeSides[contractData.globalStage - 1]?.safeTile >= 50) {
          //       rightsafe = true
          //   }
          //   else {
          //     leftsafe = true;
          //   }
        }
        <p> Safe NFT's are : {leftarray.length} </p>
        <p> died NFT's are : {dieArray.length} </p>
      </div>

    </div>
  )
}

export default RemainingNftModal;